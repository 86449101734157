import React from 'react';

const Error = () => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 45 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="10.353"
        y="33.0587"
        width="3.52942"
        height="24.7059"
        transform="rotate(-90 10.353 33.0587)"
        fill="#FF4747"
      />
      <rect
        x="24.4707"
        y="10.9998"
        width="3.14866"
        height="3.14866"
        fill="#FF4747"
      />
      <rect
        x="22.7059"
        y="8.35284"
        width="3.14866"
        height="3.14866"
        fill="#FF4747"
      />
      <rect
        x="27.1177"
        y="13.6469"
        width="3.14866"
        height="3.14866"
        fill="#FF4747"
      />
      <rect
        x="28.8824"
        y="16.2939"
        width="3.14866"
        height="3.14866"
        fill="#FF4747"
      />
      <rect
        x="33.968"
        y="22.3699"
        width="3.14866"
        height="3.14866"
        transform="rotate(-180 33.968 22.3699)"
        fill="#FF4747"
      />
      <rect
        x="36.8236"
        y="29.5292"
        width="3.52941"
        height="7.94118"
        transform="rotate(-180 36.8236 29.5292)"
        fill="#FF4747"
      />
      <rect
        width="3.14866"
        height="3.14866"
        transform="matrix(-1 0 0 1 20.9413 10.9998)"
        fill="#FF4747"
      />
      <rect
        width="3.14866"
        height="3.14866"
        transform="matrix(-1 0 0 1 22.7059 8.35284)"
        fill="#FF4747"
      />
      <rect
        width="3.14866"
        height="3.14866"
        transform="matrix(-1 0 0 1 18.2942 13.6469)"
        fill="#FF4747"
      />
      <rect
        width="3.14866"
        height="3.14866"
        transform="matrix(-1 0 0 1 16.5294 16.2939)"
        fill="#FF4747"
      />
      <rect
        width="3.14866"
        height="3.14866"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 11.4438 22.3699)"
        fill="#FF4747"
      />
      <rect
        width="3.52941"
        height="7.94118"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 8.58826 29.5292)"
        fill="#FF4747"
      />
      <rect
        x="21.5294"
        y="26.5882"
        width="2.35294"
        height="2.35294"
        transform="rotate(-90 21.5294 26.5882)"
        fill="#FF4747"
      />
      <rect
        x="23.8823"
        y="17.1764"
        width="5.29412"
        height="2.35294"
        transform="rotate(90 23.8823 17.1764)"
        fill="#FF4747"
      />
      <g filter="url(#filter0_f_1726_40)">
        <rect
          x="10.353"
          y="33.0587"
          width="3.52942"
          height="24.7059"
          transform="rotate(-90 10.353 33.0587)"
          fill="#FF4747"
        />
        <rect
          x="24.4707"
          y="10.9998"
          width="3.14866"
          height="3.14866"
          fill="#FF4747"
        />
        <rect
          x="22.7059"
          y="8.35284"
          width="3.14866"
          height="3.14866"
          fill="#FF4747"
        />
        <rect
          x="27.1177"
          y="13.6469"
          width="3.14866"
          height="3.14866"
          fill="#FF4747"
        />
        <rect
          x="28.8824"
          y="16.2939"
          width="3.14866"
          height="3.14866"
          fill="#FF4747"
        />
        <rect
          x="33.968"
          y="22.3699"
          width="3.14866"
          height="3.14866"
          transform="rotate(-180 33.968 22.3699)"
          fill="#FF4747"
        />
        <rect
          x="36.8236"
          y="29.5292"
          width="3.52941"
          height="7.94118"
          transform="rotate(-180 36.8236 29.5292)"
          fill="#FF4747"
        />
        <rect
          width="3.14866"
          height="3.14866"
          transform="matrix(-1 0 0 1 20.9413 10.9998)"
          fill="#FF4747"
        />
        <rect
          width="3.14866"
          height="3.14866"
          transform="matrix(-1 0 0 1 22.7059 8.35284)"
          fill="#FF4747"
        />
        <rect
          width="3.14866"
          height="3.14866"
          transform="matrix(-1 0 0 1 18.2942 13.6469)"
          fill="#FF4747"
        />
        <rect
          width="3.14866"
          height="3.14866"
          transform="matrix(-1 0 0 1 16.5294 16.2939)"
          fill="#FF4747"
        />
        <rect
          width="3.14866"
          height="3.14866"
          transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 11.4438 22.3699)"
          fill="#FF4747"
        />
        <rect
          width="3.52941"
          height="7.94118"
          transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 8.58826 29.5292)"
          fill="#FF4747"
        />
        <rect
          x="21.5294"
          y="26.5882"
          width="2.35294"
          height="2.35294"
          transform="rotate(-90 21.5294 26.5882)"
          fill="#FF4747"
        />
        <rect
          x="23.8823"
          y="17.1764"
          width="5.29412"
          height="2.35294"
          transform="rotate(90 23.8823 17.1764)"
          fill="#FF4747"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1726_40"
          x="0.588257"
          y="0.352844"
          width="44.2354"
          height="40.7059"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4"
            result="effect1_foregroundBlur_1726_40"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Error;
