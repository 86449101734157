import React from 'react';
import CrioSATIntroSection from './CrioSATIntroSection';
import TestScheduleCountdown from './TestScheduleCountdown';
import TestImportantNote from './TestImportantNote';

export default function CrioSATMockInterview({ interviewData }) {
  return (
    <div className="mt-[80px]">
      <CrioSATIntroSection />
      <TestScheduleCountdown
        activationTime={interviewData?.activation_time}
        interviewLink={interviewData?.link}
      />
      <TestImportantNote />
    </div>
  );
}
