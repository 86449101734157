import SandClock from '@components/HackathonPage/assets/svgs/icons/SandClock';
import React, { useEffect, useState } from 'react';
import Label from './Label';
import Cross from '@components/HackathonPage/assets/svgs/icons/Cross';
import { useNavigate } from '@reach/router';
// import DevXLogo from '@components/HackathonPage/assets/svgs/icons/DevXLogo.svg';

const SuccessModal = ({ closeDialog, isAnswerCorrect }) => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      navigate('/about-us/');
      closeDialog();
    }
  }, [countdown]);

  return (
    <div className="mx-2 max-w-[95dvw] md:w-[80dvw] md:max-w-[800px]">
      <div className="cardBorder relative flex h-full w-full items-center justify-center bg-[#F2FBF7] px-2.5 py-10 md:px-3">
        <div
          className="absolute right-4 top-4 h-[10px] cursor-pointer md:right-8 md:top-8 md:h-[18px]"
          onClick={() => {
            closeDialog();
          }}
        >
          <Cross />
        </div>
        <div className="flex w-full max-w-[300px] flex-col gap-7 text-center md:max-w-[520px]">
          <div className="h-[28px] md:h-[50px]">
            <SandClock color="#013437" />
          </div>
          <Label
            content={
              <p>
                {isAnswerCorrect
                  ? 'Congratulations! You have cracked the code.'
                  : 'Genius! You were wrong. The right answer was B) "esuaeB The tseB Way ot Learn sI To ecneirepxE". Since you were super close,'}
                <br />
                Here is your secret pass to DevX -
              </p>
            }
            contentClassName={
              'text-[#191F27] font-medium text-[12px] leading-normal'
            }
          ></Label>
          <div className="cardBorder flex h-[50px] items-center justify-center gap-6 bg-[#202833] px-10 md:h-[100px] md:w-[75%]">
            <div className="h-[28px] md:h-[50px]">
              <SandClock color="#fff" />
            </div>
            <Label
              content={
                <a href="mailto:devx-hackathon@criodo.com">
                  devx-hackathon@criodo.com
                </a>
              }
              contentClassName={'text-[#57F49E] font-semibold text-[12px]'}
            ></Label>
          </div>
          <Label
            content={
              <p>
                Redirecting in
                <br />
                {countdown}
                <br />
                See you on the other side.
              </p>
            }
            contentClassName={'text-[#191F27] font-medium text-[12px]'}
          ></Label>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
