import React, { useState } from 'react';
import Label from './Label';
import { cn } from '@/lib/utils';
import { OPTIONS } from './constants';
import { useDialog } from '@components/v5/Dialog/index';
import SuccessModal from './SuccessModal';

const Form = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const [openDialog, closeDialog] = useDialog();

  const onSubmitHandler = () => {
    openDialog({
      children: (
        <SuccessModal
          closeDialog={closeDialog}
          isAnswerCorrect={selectedOption === 2}
        />
      ),
      transparentBackground: true,
    });
  };

  return (
    <>
      <div>
        <div className="grid grid-cols-1 gap-y-4 gap-x-10 md:grid-cols-2 md:gap-y-10">
          {OPTIONS.map((option) => (
            <div
              className="cardBorderLight flex w-full cursor-pointer flex-wrap gap-3"
              onClick={() => {
                setSelectedOption(option.id);
              }}
            >
              <Label
                content={option.text}
                contentClassName={cn(
                  'flex justify-center items-center p-3 h-[50px] md:h-[100px] font-semibold text-[10px] md:text-[18px] w-full',
                  'bg-[#202833] text-white',
                  `${
                    selectedOption === option.id &&
                    'bg-[#57F49E] text-[#202833]'
                  }`,
                )}
                key={option.id}
                className={'w-full'}
              ></Label>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 flex items-center justify-center">
        <a
          href="#_"
          class={cn(
            'relative inline-block overflow-hidden rounded  px-14 py-2.5 text-white',
            `${
              !selectedOption
                ? 'bg-gray-600'
                : 'hover:to-crio-yellow-400 group bg-crio-yellow-700 transition-all duration-300 ease-out hover:bg-gradient-to-r hover:from-crio-yellow-700 hover:ring-2 hover:ring-yellow-300 hover:ring-offset-2'
            }`,
          )}
          onClick={() => {
            if (!selectedOption) return;

            onSubmitHandler();
          }}
        >
          <span class="ease absolute right-0 -mt-12 h-24 w-8 translate-x-12 rotate-12 transform bg-crio-yellow-700 opacity-10 transition-all duration-1000 group-hover:-translate-x-40"></span>
          <span class="fontMPlus relative font-semibold text-white">
            Submit
          </span>
        </a>
      </div>
    </>
  );
};

export default Form;
