import React, { useContext } from 'react';
import SEO from '@components/Common/SEO/index';
import FooterV2 from '@components/FooterV2';
import Navigation from '@components/Navigation';
import { graphql } from 'gatsby';
// import useGeekTrust from '@src/hooks/useGeekTrust';
// import FspStylePageHero from '@components/v5/FspStylePageHero';
// import HomepageHero from '@components/v5/HomepageHero';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import CrioSATMockInterview from './CrioSATMockInterview';
import HeroInfo from './HeroInfo';
import LeadGenerationOpenForm from '@components/LeadGenerationForm/LeadGenerationOpenForm';
import ReportSummarySection from './ReportSummarySection';
import WhyTakeThisSection from './WhyTakeThisSection';

export default function SkillAssessmentPage(props) {
  const globalState = useContext(GlobalStateContext);
  const savedInterviewData = globalState.interviewData;
  console.log(
    '🚀 ~ SkillAssessmentPage ~ savedInterviewData:',
    savedInterviewData,
  );

  return (
    <div>
      <SEO
        canonicalUrl={'https://crio.do/placements'}
        title={'Crio Skill Assessment'}
        description={'Book your mock interview slot with Crio'}
      />
      <div className="relative mx-4 h-fit w-screen max-w-[1300px] pb-20">
        <div className="fixed left-0 top-0 z-[1001] w-screen">
          <Navigation hideTopStrip hideCTA />
        </div>
        {savedInterviewData ? (
          <>
            <CrioSATMockInterview interviewData={savedInterviewData} />
          </>
        ) : (
          <>
            <div className="mt-[80px] flex h-full flex-col gap-10 md:h-[600px] md:flex-row">
              <HeroInfo />
              <div>
                <LeadGenerationOpenForm
                  isOpenForm={true}
                  hasProgramInterestedField
                  wrapperClassName={'flex justify-center [&>div]:h-[545px]'}
                />
              </div>
            </div>
            <ReportSummarySection />
            <WhyTakeThisSection />
            {/* <HomepageHero program={null} showOpenForm /> */}
          </>
        )}
      </div>
      <FooterV2 />
    </div>
  );
}

export const pageQuery = graphql`
  query {
    strapiPrograms(PrimaryProgramID: { eq: "FREE_TRIAL" }) {
      ...strapiProgramsFragment
    }
  }
`;
