import React from 'react';

const Check = () => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 35 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="3.69992"
        height="3.69992"
        transform="matrix(-1 0 0 1 19.4243 16.256)"
        fill="#57F49E"
      />
      <rect
        width="3.69992"
        height="3.69992"
        transform="matrix(-1 0 0 1 22.9496 12.7307)"
        fill="#57F49E"
      />
      <rect
        width="3.69992"
        height="3.69992"
        transform="matrix(-1 0 0 1 26.4747 9.20547)"
        fill="#57F49E"
      />
      <rect
        width="3.69992"
        height="3.69992"
        transform="matrix(-1 0 0 1 30 5.68027)"
        fill="#57F49E"
      />
      <rect
        width="3.69992"
        height="3.69992"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 12.1991 23.3959)"
        fill="#57F49E"
      />
      <rect
        width="3.69992"
        height="3.69992"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 8.67395 19.9556)"
        fill="#57F49E"
      />
      <rect
        width="3.69992"
        height="3.69992"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 5.14868 16.5153)"
        fill="#57F49E"
      />
      <g filter="url(#filter0_f_1726_107)">
        <rect
          width="3.69992"
          height="3.69992"
          transform="matrix(-1 0 0 1 19.4243 16.256)"
          fill="#57F49E"
        />
        <rect
          width="3.69992"
          height="3.69992"
          transform="matrix(-1 0 0 1 22.9496 12.7307)"
          fill="#57F49E"
        />
        <rect
          width="3.69992"
          height="3.69992"
          transform="matrix(-1 0 0 1 26.4747 9.20547)"
          fill="#57F49E"
        />
        <rect
          width="3.69992"
          height="3.69992"
          transform="matrix(-1 0 0 1 30 5.68027)"
          fill="#57F49E"
        />
        <rect
          width="3.69992"
          height="3.69992"
          transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 12.1991 23.3959)"
          fill="#57F49E"
        />
        <rect
          width="3.69992"
          height="3.69992"
          transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 8.67395 19.9556)"
          fill="#57F49E"
        />
        <rect
          width="3.69992"
          height="3.69992"
          transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 5.14868 16.5153)"
          fill="#57F49E"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1726_107"
          x="0.448376"
          y="0.979961"
          width="34.2519"
          height="27.1162"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.35015"
            result="effect1_foregroundBlur_1726_107"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Check;
