import { LANGUAGE_VERSIONS } from '@components/Riddle/constants';
import axios from 'axios';

const useRunCode = () => {
  const API = axios.create({
    baseURL: 'https://emkc.org/api/v2/piston',
  });

  const executeCode = async (language, sourceCode) => {
    const response = await API.post('/execute', {
      language: language,
      version: LANGUAGE_VERSIONS[language],
      files: [
        {
          content: sourceCode,
        },
      ],
    });
    return response.data;
  };

  return executeCode;
};

export default useRunCode;
