import React from 'react';

const DevXLogo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 267 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.141 28.058L39.8827 16.9737C39.5431 16.6402 39.0868 16.4519 38.61 16.4519H1.81294C0.812347 16.4519 0 17.2643 0 18.2648V26.2451V57.7997V65.78C0 66.1565 0.114588 66.5043 0.308978 66.7949C0.319209 66.8113 0.331487 66.8256 0.343764 66.8399C0.374457 66.8808 0.40515 66.9217 0.437889 66.9606C0.771422 67.3474 1.26251 67.595 1.81294 67.595H9.79317H38.61C39.0868 67.595 39.5431 67.4087 39.8827 67.0732L51.141 55.9888V28.0601V28.058ZM11.6061 55.9868V28.0601H39.5349V55.9868H11.6061Z"
        fill="white"
      />
      <path
        d="M106.998 28.058C107.999 28.058 108.811 27.2457 108.811 26.2451V18.2648C108.811 17.2643 107.999 16.4519 106.998 16.4519H70.2009C69.7242 16.4519 69.2679 16.6381 68.9282 16.9737L57.6699 28.058V37.8491C57.6699 37.8491 57.6699 37.8491 57.6699 37.8512V45.8314C57.6699 45.8314 57.6699 45.8314 57.6699 45.8335V55.9868L68.9282 67.0711C69.2679 67.4046 69.7242 67.5929 70.2009 67.5929H106.998C107.999 67.5929 108.811 66.7806 108.811 65.78V57.7997C108.811 56.7991 107.999 55.9868 106.998 55.9868H69.276V47.6444H106.996C107.997 47.6444 108.809 46.832 108.809 45.8314V37.8512C108.809 36.8506 107.997 36.0383 106.996 36.0383H69.276V28.058H106.998Z"
        fill="white"
      />
      <path
        d="M169.744 46.2104V18.5353C169.744 17.5347 168.932 16.7224 167.931 16.7224H159.951C158.948 16.7224 158.138 17.5347 158.138 18.5353V42.7277L142.953 57.9127L127.406 42.3655V18.3532C127.406 17.3526 126.594 16.5403 125.593 16.5403H117.613C116.61 16.5403 115.8 17.3526 115.8 18.3532V46.2636C115.8 46.7056 115.964 47.1312 116.254 47.4606C116.342 47.653 116.463 47.8351 116.622 47.9947L126.416 57.7878L127.406 58.7946V58.7782L135.581 66.9528C135.904 67.2761 136.317 67.4501 136.739 67.4787V67.4992H149.071V67.4869C149.525 67.4787 149.976 67.3027 150.324 66.9569L169.286 47.9947C169.769 47.5118 169.922 46.8222 169.746 46.2083L169.744 46.2104Z"
        fill="white"
      />
      <path
        d="M162.112 47.0068H162.116L162.112 47.0103V47.0068Z"
        fill="white"
      />
      <path
        d="M216.384 95.0654V95.0908L216.373 95.0781L216.384 95.0654Z"
        fill="#494949"
      />
      <path
        d="M206.818 33.0846L199.423 41.2388L176.807 16.3077V0.00146484L206.818 33.0846Z"
        fill="url(#paint0_linear_1670_8700)"
      />
      <path
        d="M221.62 33.0826L221.615 33.0867L183.39 75.2223L183.372 75.2428V58.9344L199.424 41.2388L206.821 33.0867V33.0846H206.819L176.81 0.00146484H191.602L214.22 24.9325V24.9284L221.62 33.0826Z"
        fill="url(#paint1_linear_1670_8700)"
      />
      <path
        d="M221.619 33.0822L221.615 33.0863V49.3864L198.175 75.2219H183.39L183.371 75.2424V75.2342L221.615 33.0781L221.619 33.0822Z"
        fill="url(#paint2_linear_1670_8700)"
      />
      <path
        d="M221.618 33.0808L221.615 33.0835V33.0781L221.618 33.0808Z"
        fill="#494949"
      />
      <path
        d="M176.807 0.00224144H176.803L176.805 0L176.807 0.00224144Z"
        fill="#494949"
      />
      <path
        d="M236.413 49.392L243.808 41.2378L266.425 66.1688V82.4751L236.413 49.392Z"
        fill="#9E9E9E"
      />
      <path
        d="M236.413 49.392L243.808 41.2378L266.425 66.1688V82.4751L236.413 49.392Z"
        fill="url(#paint3_linear_1670_8700)"
      />
      <path
        d="M221.615 49.3945L221.619 49.3905L259.845 7.25484L259.863 7.23438V23.5427L243.808 41.2383L236.411 49.3905V49.3925H236.413L266.423 82.4757H251.631L229.014 57.5446L229.012 57.5487L221.615 49.3945Z"
        fill="white"
      />
      <path
        d="M221.615 49.3945L221.619 49.3905L259.845 7.25484L259.863 7.23438V23.5427L243.808 41.2383L236.411 49.3905V49.3925H236.413L266.423 82.4757H251.631L229.014 57.5446L229.012 57.5487L221.615 49.3945Z"
        fill="url(#paint4_linear_1670_8700)"
      />
      <path
        d="M221.615 49.3945L221.619 49.3905V33.0903L245.057 7.25484H259.845L259.863 7.23438V7.24256L221.619 49.3986L221.615 49.3945Z"
        fill="#9E9E9E"
      />
      <path
        d="M221.615 49.3945L221.619 49.3905V33.0903L245.057 7.25484H259.845L259.863 7.23438V7.24256L221.619 49.3986L221.615 49.3945Z"
        fill="url(#paint5_linear_1670_8700)"
      />
      <path
        d="M221.615 49.3933L221.618 49.3906V49.396L221.615 49.3933Z"
        fill="#494949"
      />
      <path
        d="M266.427 82.4756H266.431L266.429 82.4778L266.427 82.4756Z"
        fill="#494949"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1670_8700"
          x1="212.764"
          y1="-0.0706505"
          x2="180.832"
          y2="12.4322"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.138196" stop-color="#0069CB" />
          <stop offset="1" stop-color="#01E3AB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1670_8700"
          x1="199.215"
          y1="0.00146484"
          x2="199.215"
          y2="75.2428"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA800" />
          <stop offset="0.401425" stop-color="#F2F62E" />
          <stop offset="1" stop-color="#2DF8C5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1670_8700"
          x1="229.196"
          y1="33.0044"
          x2="191.291"
          y2="51.5034"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.138196" stop-color="#0069CB" />
          <stop offset="1" stop-color="#00E2AB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1670_8700"
          x1="251.419"
          y1="41.2378"
          x2="251.419"
          y2="82.4751"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.240911" stop-color="#02BF91" />
          <stop offset="1" stop-color="#2DF8C5" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1670_8700"
          x1="244.019"
          y1="7.23437"
          x2="244.019"
          y2="82.4757"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.141261" stop-color="#F2F62E" />
          <stop offset="1" stop-color="#01E3AB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1670_8700"
          x1="240.739"
          y1="7.23437"
          x2="240.739"
          y2="49.3986"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.138196" stop-color="#02BF91" />
          <stop offset="1" stop-color="#2DF8C5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DevXLogo;
