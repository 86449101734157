import React from 'react';
import IconWrapper from './IconWrapper';
import { cn } from '@/lib/utils';

const Label = ({
  className,
  content,
  contentClassName,
  icon,
  iconClassName,
}) => {
  return (
    <div
      className={cn(
        'flex items-center justify-start gap-3 md:gap-5',
        className,
      )}
    >
      {icon ? (
        <IconWrapper className={iconClassName}>{icon}</IconWrapper>
      ) : (
        <></>
      )}
      <p
        className={cn(
          `fontMPlus flex-1 font-normal md:text-[20px] md:leading-[30px]`,
          'text-[12px] leading-[20px]',
          contentClassName,
        )}
      >
        {content}
      </p>
    </div>
  );
};

export default Label;
