import { cn } from '@/lib/utils';
import React from 'react';
import Button from './Button';
import HighlightedButton from './HighlightedButton';
import LanguageSelector from './LanguageSelector';

const CodeEditorFooter = ({
  onReset,
  runCode,
  isLoading,
  language,
  onSelect,
}) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between gap-4 bg-gray-600 px-4 md:h-[60px] md:p-2',
        'h-[40px] p-1',
      )}
    >
      <div>
        <LanguageSelector
          language={language}
          onSelect={onSelect}
          className={'hidden md:block'}
        />
      </div>
      <div className="flex gap-4">
        <Button
          onClick={() => {
            onReset();
          }}
        >
          Reset Code
        </Button>

        <HighlightedButton
          onClick={() => {
            runCode();
          }}
          isLoading={isLoading}
        >
          Run Code
        </HighlightedButton>
      </div>
    </div>
  );
};

export default CodeEditorFooter;
