import React from 'react';

const Question = () => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 17 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.94116"
        y="28.8232"
        width="3.52941"
        height="4.11765"
        transform="rotate(-90 5.94116 28.8232)"
        fill="#57F49E"
      />
      <rect
        width="3.14866"
        height="3.14866"
        transform="matrix(-1 0 0 1 13.8823 12.6465)"
        fill="#57F49E"
      />
      <rect
        width="3.14866"
        height="3.14866"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 8.79675 18.7227)"
        fill="#57F49E"
      />
      <rect
        width="4.11765"
        height="5.88235"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 5.94116 23.5288)"
        fill="#57F49E"
      />
      <rect
        width="4.11765"
        height="8.23529"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 12.4117 12.9409)"
        fill="#57F49E"
      />
      <rect
        width="4.11765"
        height="8.23529"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 0.0588379 12.9409)"
        fill="#57F49E"
      />
      <rect
        width="4.11765"
        height="8.23529"
        transform="matrix(-4.37114e-08 1 1 4.37114e-08 4.17651 0.587891)"
        fill="#57F49E"
      />
    </svg>
  );
};

export default Question;
