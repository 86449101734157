import React, { useEffect, useState } from 'react';
import SandClock from '@components/HackathonPage/assets/svgs/icons/SandClock';
import Label from '@components/Riddle/Label';
import Error from '@components/HackathonPage/assets/svgs/icons/Error';
import Check from '@components/HackathonPage/assets/svgs/icons/Check';
import Question from '@components/HackathonPage/assets/svgs/icons/Question';

import CodeEditor from '@components/Riddle/CodeEditor';
import Pointer from '@components/HackathonPage/assets/svgs/icons/Pointer';
import Form from '@components/Riddle/Form';
import CrioLogo from '@components/HackathonPage/assets/svgs/icons/CrioLogo';
import SEO from '@components/Common/SEO/index';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import DevXLogo from '@components/HackathonPage/assets/svgs/icons/DevXLogo';

export default function DevX() {
  const [preLoader, setPreLoader] = useState(true);

  useEffect(() => {
    if (document?.readyState === 'complete') {
      setPreLoader(false);
    }

    window.addEventListener('load', () => {
      setPreLoader(false);
    });
  }, []);

  return (
    <>
      <SEO
        title={'DevX - Because the best way to learn is to experience'}
        description={
          'Join us for an experiential hackathon with DevX by Crio.Do. ​​Tackle real-world challenges from top product companies, collaborate with tech enthusiasts, and get a chance to demonstrate your solutions to top industry experts.'
        }
        canonicalUrl={''}
        meta={[
          {
            name: `og:image`,
            content: 'https://crio-assets.s3.ap-south-1.amazonaws.com/DevX.png',
          },
        ]}
      ></SEO>
      <div
        className={cn(
          'fixed top-0 left-0 z-[100] flex h-[100dvh] w-[100dvw] items-start justify-center bg-[#191F27]',
          `${!preLoader && 'fadeOut'}`,
        )}
        id="preloader"
      >
        <div className="flex h-full items-center justify-center">
          <div className="w-[150px] md:w-[500px]">
            <DevXLogo />
          </div>
        </div>
      </div>
      <div className="relative flex h-[100dvh] w-[100dvw] items-center justify-center bg-[#151A20] pt-[60px]">
        <div
          className="absolute top-0 left-0 flex h-[100dvh] w-full justify-center px-8 pt-6 md:h-[650px] md:pt-6"
          style={{
            background:
              'linear-gradient(178.9deg, #202833 10.37%, #57F49E 170.58%)',
          }}
        >
          <div className="flex w-[92dvw] justify-center md:max-w-[1190px] md:justify-start">
            <div className="h-[40px]">
              <CrioLogo />
            </div>
          </div>
        </div>
        <div
          className={`cardBorder z-[10] flex h-[calc(90dvh-50px)] w-[92dvw] flex-col gap-4 overflow-y-auto bg-[#202833] p-3 py-5 md:max-w-[1190px] md:gap-10 md:px-7 md:py-10`}
        >
          {[
            <Label
              icon={<SandClock />}
              content={
                'console_125_p()_ indexing_stream... C.log_stdi_###..user..#b_access loading….'
              }
              contentClassName={'text-[#57F49E] md:flex-none flex-1'}
              iconClassName={'h-[18px] md:h-[28px]'}
              className={'flex-row-reverse items-start md:items-center'}
            ></Label>,
            <Label
              icon={<Error />}
              content={'Error 403 - Your access has been denied.'}
              contentClassName={'text-[#FF4747]'}
              iconClassName={'h-[20px] md:h-[38px]'}
            ></Label>,
            <Label
              icon={<Check />}
              content={
                'Please verify that you are a human developer - Crack the code below in order to access your secret pass.'
              }
              contentClassName={'text-white'}
              iconClassName={'h-[18px] md:h-[28px]'}
              className={'items-start md:items-center'}
            ></Label>,
            <Label
              icon={<Question />}
              content={
                <p>
                  The code below processes an input string and returns a
                  specific value.
                  <span className="text-[#FF4747]">(Beware of Bugs)</span>
                  <br /> What is the output when the input is “The Best Way To
                  Learn Is To Experience”?
                </p>
              }
              contentClassName={'text-white'}
              iconClassName={'h-[16px] md:h-[28px]'}
              className={'items-start'}
            ></Label>,
            <div className="flex flex-col gap-5">
              <Label
                content={'Debug & run the code here!'}
                contentClassName={'text-white'}
              ></Label>

              <CodeEditor />
            </div>,
            <div className="flex flex-col gap-4 md:gap-10">
              <Label
                icon={<Pointer />}
                content={'Please choose the correct answer:'}
                contentClassName={'text-white'}
                iconClassName={'h-[16px] md:h-[32px]'}
              ></Label>
              <Form />
            </div>,
          ].map((animationDiv, i) => (
            <motion.div
              initial={{
                opacity: 0,
                translateY: 50,
              }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.5, delay: i * 1.5 }}
            >
              {animationDiv}
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
}
