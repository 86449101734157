import React from 'react';

const Cursor = () => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3332 18.3148L30.8119 17.461L32.5245 20.4273L10.2941 33.2621L8.58458 30.3012L10.0548 29.4524L9.19718 27.967L10.6765 27.1129L9.81969 25.6288L11.3101 24.7683L10.4529 23.2836L11.9319 22.4296L11.076 20.9471L12.5585 20.0912L11.7015 18.6068L13.1837 17.751L12.3278 16.2685L13.8112 15.4121L12.9555 13.9299L14.4405 13.0725L13.5848 11.5903L15.0667 10.7347L14.2164 9.26199L17.1783 7.55195L19.7501 12.0065L21.2376 11.1476L21.2327 11.1463C21.5143 11.6341 21.7958 12.1216 22.0831 12.6193L19.1089 14.3365L16.5491 9.90283L15.0853 10.748L15.94 12.2283L14.4555 13.0853L15.3093 14.564L13.8274 15.4196L14.6879 16.91L13.2025 17.7676L14.0561 19.246L12.5761 20.1005L13.4339 21.5861L11.9485 22.4437L12.8 23.9186L11.3185 24.774L12.1786 26.2638L10.6917 27.1223L11.5456 28.6013L10.0792 29.4479L10.9231 30.9096L30.1872 19.7874L29.3342 18.31L29.3326 18.3151L29.3332 18.3148Z"
        fill="#57F49E"
      />
      <path
        d="M26.3196 6.23302L27.7958 5.38074L30.3631 9.82734L28.8938 10.6756L29.7481 12.1553L26.7834 13.8669L27.6392 15.3491L26.1627 16.2016L27.0126 17.6737L28.4761 16.8287C28.7663 17.3314 29.05 17.8227 29.3337 18.3141L29.3353 18.3089L27.8452 19.1692L26.9975 17.7009L25.5283 18.5492L23.8076 15.569L26.7651 13.8615L25.9101 12.3806L28.8764 10.668L28.7587 10.4642C28.1598 9.42685 27.5602 8.38983 26.9619 7.35208C26.7465 6.97827 26.5346 6.60244 26.3211 6.22753L26.3198 6.23252L26.3196 6.23302Z"
        fill="#57F49E"
      />
      <path
        d="M21.2384 11.1476L20.3806 9.66191C20.449 9.61738 20.506 9.57648 20.5667 9.54141C21.4354 9.03902 22.3042 8.53744 23.1736 8.03632C23.2312 8.00307 23.2926 7.97648 23.3519 7.94689L23.3469 7.94556L24.2044 9.43092L21.2334 11.1463L21.2384 11.1476Z"
        fill="#57F49E"
      />
      <path
        d="M23.3517 7.94629L22.4973 6.46631L25.4652 4.75281L26.3196 6.23279L26.321 6.2278C25.3295 6.80025 24.3379 7.37269 23.3464 7.94514L23.3514 7.94648L23.3517 7.94629Z"
        fill="#57F49E"
      />
      <g filter="url(#filter0_f_1726_296)">
        <path
          d="M29.3332 18.3148L30.8119 17.461L32.5245 20.4273L10.2941 33.2621L8.58458 30.3012L10.0548 29.4524L9.19718 27.967L10.6765 27.1129L9.81969 25.6288L11.3101 24.7683L10.4529 23.2836L11.9319 22.4296L11.076 20.9471L12.5585 20.0912L11.7015 18.6068L13.1837 17.751L12.3278 16.2685L13.8112 15.4121L12.9555 13.9299L14.4405 13.0725L13.5848 11.5903L15.0667 10.7347L14.2164 9.26199L17.1783 7.55195L19.7501 12.0065L21.2376 11.1476L21.2327 11.1463C21.5143 11.6341 21.7958 12.1216 22.0831 12.6193L19.1089 14.3365L16.5491 9.90283L15.0853 10.748L15.94 12.2283L14.4555 13.0853L15.3093 14.564L13.8274 15.4196L14.6879 16.91L13.2025 17.7676L14.0561 19.246L12.5761 20.1005L13.4339 21.5861L11.9485 22.4437L12.8 23.9186L11.3185 24.774L12.1786 26.2638L10.6917 27.1223L11.5456 28.6013L10.0792 29.4479L10.9231 30.9096L30.1872 19.7874L29.3342 18.31L29.3326 18.3151L29.3332 18.3148Z"
          fill="#57F49E"
        />
        <path
          d="M26.3196 6.23302L27.7958 5.38074L30.3631 9.82734L28.8938 10.6756L29.7481 12.1553L26.7834 13.8669L27.6392 15.3491L26.1627 16.2016L27.0126 17.6737L28.4761 16.8287C28.7663 17.3314 29.05 17.8227 29.3337 18.3141L29.3353 18.3089L27.8452 19.1692L26.9975 17.7009L25.5283 18.5492L23.8076 15.569L26.7651 13.8615L25.9101 12.3806L28.8764 10.668L28.7587 10.4642C28.1598 9.42685 27.5602 8.38983 26.9619 7.35208C26.7465 6.97827 26.5346 6.60244 26.3211 6.22753L26.3198 6.23252L26.3196 6.23302Z"
          fill="#57F49E"
        />
        <path
          d="M21.2384 11.1476L20.3806 9.66191C20.449 9.61738 20.506 9.57648 20.5667 9.54141C21.4354 9.03902 22.3042 8.53744 23.1736 8.03632C23.2312 8.00307 23.2926 7.97648 23.3519 7.94689L23.3469 7.94556L24.2044 9.43092L21.2334 11.1463L21.2384 11.1476Z"
          fill="#57F49E"
        />
        <path
          d="M23.3517 7.94629L22.4973 6.46631L25.4652 4.75281L26.3196 6.23279L26.321 6.2278C25.3295 6.80025 24.3379 7.37269 23.3464 7.94514L23.3514 7.94648L23.3517 7.94629Z"
          fill="#57F49E"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_1726_296"
          x="3.87871"
          y="0.0469255"
          width="33.3517"
          height="37.921"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.35294"
            result="effect1_foregroundBlur_1726_296"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Cursor;
