import React from 'react';

const Cross = () => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.0390625" width="2.57134" height="2.57134" fill="#191F27" />
      <rect
        x="18.0391"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 18.0391 0)"
        fill="#191F27"
      />
      <rect x="0.0390625" width="2.57134" height="2.57134" fill="#191F27" />
      <rect
        x="18.0391"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 18.0391 0)"
        fill="#191F27"
      />
      <rect
        x="2.61084"
        y="2.57129"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="15.467"
        y="2.57129"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 15.467 2.57129)"
        fill="#191F27"
      />
      <rect
        x="2.61084"
        y="2.57129"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="15.467"
        y="2.57129"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 15.467 2.57129)"
        fill="#191F27"
      />
      <rect
        x="5.18115"
        y="5.14282"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="12.897"
        y="5.14282"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 12.897 5.14282)"
        fill="#191F27"
      />
      <rect
        x="5.18115"
        y="5.14282"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="12.897"
        y="5.14282"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 12.897 5.14282)"
        fill="#191F27"
      />
      <rect
        x="7.75293"
        y="7.71432"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="10.325"
        y="7.71432"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 10.325 7.71432)"
        fill="#191F27"
      />
      <rect
        x="7.75293"
        y="7.71432"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="10.325"
        y="10.2858"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="7.75293"
        y="10.2858"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 7.75293 10.2858)"
        fill="#191F27"
      />
      <rect
        x="10.325"
        y="10.2858"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="7.75293"
        y="10.2858"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 7.75293 10.2858)"
        fill="#191F27"
      />
      <rect
        x="12.897"
        y="12.8571"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="5.18115"
        y="12.8571"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 5.18115 12.8571)"
        fill="#191F27"
      />
      <rect
        x="12.897"
        y="12.8571"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="5.18115"
        y="12.8571"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 5.18115 12.8571)"
        fill="#191F27"
      />
      <rect
        x="15.467"
        y="15.4286"
        width="2.57134"
        height="2.57134"
        fill="#191F27"
      />
      <rect
        x="2.61084"
        y="15.4286"
        width="2.57134"
        height="2.57134"
        transform="rotate(90 2.61084 15.4286)"
        fill="#191F27"
      />
    </svg>
  );
};

export default Cross;
