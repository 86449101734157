export const LANGUAGE_VERSIONS = {
  javascript: '18.15.0',
  python: '3.10.0',
  java: '15.0.2',
};

export const CODE_SNIPPETS = {
  javascript: `
  function sentenceProcess(sentence) {
    return sentence.split(" ").map((word, index) => 
      index % 2 == 0 ? word.split("").reverse().join("") 
      : word.split().join("")).join(" ");
  }
  
  console.log(sentenceProcess("Because The Best Way to Learn Is To Experience"));

  error // remove this line
  `,
  python: `def sentence_process(sentence):
  return ' '.join(
      word[::-1] if index % 2 == 0 else word
      for index, word in enumerate(sentence.split())
  )

print(sentence_process("Because The Best Way to Learn Is To Experience"))


error // remove this line`,
  java: `
  public class SentenceProcess {
    public static String sentenceProcess(String sentence) {
        String[] words = sentence.split(" ");
        StringBuilder result = new StringBuilder();
        
        for (int i = 0; i < words.length; i++) {
            if (i % 2 == 0) {
                result.append(new StringBuilder(words[i]).reverse().toString());
            } else {
                result.append(words[i]);
            }
            if (i < words.length - 1) {
                result.append(" ");
            }
        }
        
        return result.toString();
    }
    
    public static void main(String[] args) {
        System.out.println(sentenceProcess("Because The Best Way to Learn Is To Experience"));
    }

    error // remove this line
}`,
};

export const OPTIONS = [
  { id: 1, text: 'A) "esuaeB ehT tseB yaW ot nraeL sI oT ecneirepxE"' },
  { id: 2, text: 'B) "esuaeB The tseB Way ot Learn sI To ecneirepxE"' },
  { id: 3, text: 'C) "Because ehT tseB yaW to nraeL Is oT ecneirepxE"' },
  { id: 4, text: 'D) "esuaeB The tseB yaW to Learn sI oT ecneirepxE"' },
];
