import { cn } from '@/lib/utils';
import React from 'react';

const IconWrapper = ({ children, className }) => {
  return (
    <div
      className={cn(
        `iconBorder ml-2 flex items-center justify-center md:h-[50px] md:w-[50px]`,
        'h-[28px] w-[28px]',
      )}
    >
      <div className={className}>{children}</div>
    </div>
  );
};

export default IconWrapper;
