import React from 'react';

const SandClock = ({ color }) => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.13086"
        y="2.68729"
        width="2.09911"
        height="9.1836"
        fill={color || '#57F49E'}
      />
      <rect
        x="18.1365"
        y="2.68729"
        width="2.09911"
        height="16.0057"
        transform="rotate(90 18.1365 2.68729)"
        fill={color || '#57F49E'}
      />
      <rect
        x="19.9734"
        y="0.588135"
        width="2.09911"
        height="19.6791"
        transform="rotate(90 19.9734 0.588135)"
        fill={color || '#57F49E'}
      />
      <rect
        x="19.9734"
        y="27.3514"
        width="2.09911"
        height="19.6791"
        transform="rotate(90 19.9734 27.3514)"
        fill={color || '#57F49E'}
      />
      <rect
        x="4.22998"
        y="11.8708"
        width="2.09911"
        height="2.09911"
        fill={color || '#57F49E'}
      />
      <rect
        x="6.3291"
        y="13.97"
        width="2.09911"
        height="2.09911"
        fill={color || '#57F49E'}
      />
      <rect
        x="13.9382"
        y="11.8708"
        width="2.09911"
        height="2.09911"
        fill={color || '#57F49E'}
      />
      <rect
        x="11.8391"
        y="13.97"
        width="2.09911"
        height="2.09911"
        fill={color || '#57F49E'}
      />
      <rect
        x="16.0376"
        y="2.68729"
        width="2.09911"
        height="9.1836"
        fill={color || '#57F49E'}
      />
      <rect
        x="18.1365"
        y="27.3514"
        width="2.09911"
        height="9.1836"
        transform="rotate(-180 18.1365 27.3514)"
        fill={color || '#57F49E'}
      />
      <rect
        x="2.13086"
        y="27.3514"
        width="2.09911"
        height="16.0057"
        transform="rotate(-90 2.13086 27.3514)"
        fill={color || '#57F49E'}
      />
      <rect
        x="16.0376"
        y="18.1683"
        width="2.09911"
        height="2.09911"
        transform="rotate(-180 16.0376 18.1683)"
        fill={color || '#57F49E'}
      />
      <rect
        x="13.9382"
        y="16.0687"
        width="2.09911"
        height="2.09911"
        transform="rotate(-180 13.9382 16.0687)"
        fill={color || '#57F49E'}
      />
      <rect
        x="6.3291"
        y="18.1683"
        width="2.09911"
        height="2.09911"
        transform="rotate(-180 6.3291 18.1683)"
        fill={color || '#57F49E'}
      />
      <rect
        x="8.42798"
        y="16.0687"
        width="2.09911"
        height="2.09911"
        transform="rotate(-180 8.42798 16.0687)"
        fill={color || '#57F49E'}
      />
      <rect
        x="7.11621"
        y="21.3168"
        width="2.09911"
        height="2.09911"
        transform="rotate(-90 7.11621 21.3168)"
        fill={color || '#57F49E'}
      />
      <rect
        x="5.01709"
        y="23.416"
        width="2.09911"
        height="2.09911"
        transform="rotate(-90 5.01709 23.416)"
        fill={color || '#57F49E'}
      />
      <rect
        x="13.4133"
        y="23.416"
        width="2.09911"
        height="2.09911"
        transform="rotate(-90 13.4133 23.416)"
        fill={color || '#57F49E'}
      />
      <rect
        x="9.21533"
        y="23.416"
        width="2.09911"
        height="2.09911"
        transform="rotate(-90 9.21533 23.416)"
        fill={color || '#57F49E'}
      />
      <rect
        x="13.4133"
        y="19.2177"
        width="2.09911"
        height="2.09911"
        transform="rotate(90 13.4133 19.2177)"
        fill={color || '#57F49E'}
      />
      <rect
        x="11.3145"
        y="17.1185"
        width="2.09911"
        height="2.09911"
        transform="rotate(90 11.3145 17.1185)"
        fill={color || '#57F49E'}
      />
      <rect
        x="11.3145"
        y="11.8708"
        width="2.09911"
        height="2.09911"
        transform="rotate(-180 11.3145 11.8708)"
        fill={color || '#57F49E'}
      />
      <rect
        x="4.22998"
        y="27.3514"
        width="2.09911"
        height="9.1836"
        transform="rotate(-180 4.22998 27.3514)"
        fill={color || '#57F49E'}
      />
    </svg>
  );
};

export default SandClock;
