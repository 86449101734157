import React from 'react';

const Pointer = () => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6.49646"
        y="1.58044"
        width="1.58004"
        height="12.377"
        fill="#57F49E"
      />
      <rect
        x="0.176514"
        y="9.06104"
        width="1.58189"
        height="4.8401"
        fill="#57F49E"
      />
      <rect
        x="5.01648"
        y="10.5728"
        width="1.58189"
        height="1.67633"
        fill="#57F49E"
      />
      <rect
        x="11.5"
        y="1.58044"
        width="1.58004"
        height="12.377"
        fill="#57F49E"
      />
      <rect
        x="24.2823"
        y="28.3282"
        width="1.58189"
        height="16.1022"
        transform="rotate(90 24.2823 28.3282)"
        fill="#57F49E"
      />
      <rect
        x="11.5"
        y="0.00012207"
        width="1.58004"
        height="3.42342"
        transform="rotate(90 11.5 0.00012207)"
        fill="#57F49E"
      />
      <rect
        x="16.2312"
        y="2.66394"
        width="1.58189"
        height="3.2346"
        transform="rotate(90 16.2312 2.66394)"
        fill="#57F49E"
      />
      <rect
        x="5.01648"
        y="8.9906"
        width="1.58189"
        height="4.81649"
        transform="rotate(90 5.01648 8.9906)"
        fill="#57F49E"
      />
      <rect
        x="21.0477"
        y="4.24512"
        width="1.58189"
        height="3.2346"
        transform="rotate(90 21.0477 4.24512)"
        fill="#57F49E"
      />
      <rect
        x="24.2823"
        y="5.87524"
        width="1.58189"
        height="1.65272"
        transform="rotate(90 24.2823 5.87524)"
        fill="#57F49E"
      />
      <rect
        x="25.9352"
        y="7.48083"
        width="1.58189"
        height="1.65272"
        transform="rotate(90 25.9352 7.48083)"
        fill="#57F49E"
      />
      <rect
        x="16.2312"
        y="4.24512"
        width="1.58189"
        height="6.32754"
        fill="#57F49E"
      />
      <rect
        x="21.0477"
        y="5.87524"
        width="1.58189"
        height="6.32754"
        fill="#57F49E"
      />
      <rect
        x="25.8644"
        y="9.06104"
        width="1.58189"
        height="11.0732"
        fill="#57F49E"
      />
      <rect
        x="24.2823"
        y="20.1337"
        width="1.58189"
        height="5.12342"
        fill="#57F49E"
      />
      <rect
        x="22.7007"
        y="25.257"
        width="1.58189"
        height="4.65122"
        fill="#57F49E"
      />
      <rect
        x="8.18018"
        y="25.257"
        width="1.58189"
        height="4.65122"
        fill="#57F49E"
      />
      <rect
        x="6.59827"
        y="21.9055"
        width="1.58189"
        height="3.35265"
        fill="#57F49E"
      />
      <rect
        x="5.01648"
        y="18.5526"
        width="1.58189"
        height="3.35265"
        fill="#57F49E"
      />
      <rect
        x="3.43433"
        y="15.2003"
        width="1.58189"
        height="3.35265"
        fill="#57F49E"
      />
      <rect
        x="1.75806"
        y="13.9023"
        width="1.58189"
        height="1.29856"
        fill="#57F49E"
      />
    </svg>
  );
};

export default Pointer;
