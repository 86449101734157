import { useContext, useEffect, useState } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { EActions } from '@src/constants/general/index';
import useMarketingPopupEvents from './useMarketingPopupEvents';
import useResizer from '@components/extra/useResizer';
import { isWithinInterval } from 'date-fns';
import { useLocation } from '@reach/router';
import { getQueryParams } from '@src/utils/helper';
import { isEmpty } from 'lodash';
import useMarketingPopupData from './useMarketingPopupData';

const useDownloadCurriculumPopups = ({ setOpen }) => {
  const {
    popupTimerDuration,
    popupVisibilityDuration,
    trialSessionPopupVisibilityDuration,
    popupVisibilityScrollPercentageDesktop,
    popupVisibilityScrollPercentageMobile,
  } = useMarketingPopupData();

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const location = useLocation();

  const query = getQueryParams(location?.search) || {};

  const isMobile = useResizer();

  const { popupViewHandler } = useMarketingPopupEvents();

  const [showPopup, setShowPopup] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [variant, setVariant] = useState(false);

  const showPopupHandler = (popupCondition) => {
    console.log('🚀 ~ showPopupHandler ~ chatbotOpened:', state.chatbotOpened);
    if (state.isLeadGenFormOpen || state.chatbotOpened) {
      return;
    }

    popupViewHandler(variant, popupCondition);
    setShowPopup(true);
    setOpen(true);
    dispatch({
      type: EActions.SET_POPUP_SHOWN_TIMESTAMP,
      payload: Date.now(),
    });
  };

  const trialSessionVisibilityHandler = () => {
    let isTrialSessionPopupVisible = false;

    const day = new Date().getDay();

    if (day === 0 || day === 6) {
      isTrialSessionPopupVisible = isWithinInterval(new Date(), {
        start: new Date(new Date().setHours(13, 0, 0, 0)),
        end: new Date(new Date().setHours(14, 30, 0, 0)),
      });
    } else {
      isTrialSessionPopupVisible = isWithinInterval(new Date(), {
        start: new Date(new Date().setHours(19, 30, 0, 0)),
        end: new Date(new Date().setHours(21, 0, 0, 0)),
      });
    }

    return isTrialSessionPopupVisible;
  };

  useEffect(() => {
    const lastShown = state.popupShownTimestamp;
    const now = Date.now();

    let isTrialSessionPopupVisible = trialSessionVisibilityHandler();

    if (
      lastShown &&
      now - lastShown < popupVisibilityDuration &&
      !isTrialSessionPopupVisible
    ) {
      // If the popup was shown in the last 2 hours, do nothing
      return;
    }

    if (
      isTrialSessionPopupVisible &&
      lastShown &&
      now - lastShown < trialSessionPopupVisibilityDuration
    ) {
      // If the popup was shown in the last 5 minutes, do nothing
      return;
    }

    // Show popup after 8 seconds
    const id = setTimeout(() => {
      popupHandler();
      showPopupHandler('TIMER');
      body.removeEventListener('scroll', handleScroll);
    }, popupTimerDuration);

    setTimeoutId(id);

    const body = document.querySelector('body');

    // Show popup if user scrolls 20% of the page
    const handleScroll = () => {
      const body = document.querySelector('body');

      const scrollPercentage = (body.scrollTop / body.scrollHeight) * 100;
      if (isMobile) {
        if (scrollPercentage > popupVisibilityScrollPercentageMobile) {
          popupHandler();
          showPopupHandler('SCROLL');
          clearTimeout(id);
          body.removeEventListener('scroll', handleScroll); // Remove the event listener
        }
      } else {
        if (scrollPercentage > popupVisibilityScrollPercentageDesktop) {
          popupHandler();
          showPopupHandler('SCROLL');
          clearTimeout(id);
          body.removeEventListener('scroll', handleScroll); // Remove the event listener
        }
      }
    };

    body.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(id);
      body.removeEventListener('scroll', handleScroll);
    };
  }, [state.isLeadGenFormOpen, state.chatbotOpened]);

  const popupHandler = () => {
    if (query?.popupVariant) {
      setVariant(parseInt(query?.popupVariant));
      return;
    }

    const isNewUser = isEmpty(state.popupData);
    const popupVariant = state.popupData?.popupVariant;

    let isTrialSessionPopupVisible = trialSessionVisibilityHandler();

    if (isTrialSessionPopupVisible) {
      setVariant(7);
    } else if (isNewUser) {
      setVariant(1);
      dispatch({
        type: EActions.SET_POPUP_DATA,
        payload: { popupVariant: 1 },
      });
    } else {
      setVariant(popupVariant + 1);

      dispatch({
        type: EActions.SET_POPUP_DATA,
        payload: { popupVariant: (popupVariant % 5) + 1 },
      });
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setOpen(false);
    setVariant(false);
    clearTimeout(timeoutId);
  };

  return { showPopup, variant, closePopup };
};

export default useDownloadCurriculumPopups;
