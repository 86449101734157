import React from 'react';

export default function CrioSATIntroSection() {
  const techSkills = ['JavaScript', 'HTML/CSS', 'React', 'Node.js'];

  return (
    <section className="w-full bg-white px-6 py-12 text-center md:py-20">
      <div className="mx-auto max-w-5xl text-center">
        <h1 className="mb-4 font-manrope text-[24px] font-extrabold text-v5-neutral-600 md:text-[50px]">
          Welcome To Crio-SAT
        </h1>
        <h2 className="mb-6 font-manrope text-[16px] font-semibold text-v5-neutral-600 md:text-[24px]">
          AI-Powered Mock Interview for Skill Assessment
        </h2>
        <p className="mx-auto mb-10 max-w-3xl font-rubik text-[10px] text-v5-neutral-500 md:text-[16px]">
          You’re all set to take Crio-SAT powered by <strong>Geektrust</strong>.
          This assessment
          <strong>
            {' '}
            mirrors the technical evaluation rounds of top tech companies
          </strong>
          , giving you real-world exposure to industry hiring standards while
          helping you
          <strong>
            {' '}
            assess your skills and identify areas for improvement
          </strong>
          .
        </p>

        <h3 className="mb-4 font-manrope text-[24px] font-extrabold text-v5-neutral-600 md:text-[40px]">
          What to Expect in the Test?
        </h3>
        <p className="mb-6 font-rubik text-[16px] text-v5-neutral-500 md:text-[24px]">
          The Crio-SAT evaluates your Full Stack Development skills across four
          key segments:
        </p>

        <div className="mx-auto grid max-w-2xl grid-cols-2 gap-4 md:grid-cols-4">
          {techSkills.map((skill, index) => (
            <div
              key={index}
              className="rounded-xl bg-v5-green-300 py-3 font-manrope text-[16px] font-semibold text-white md:text-[24px]"
            >
              {skill}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
