import React, { useContext, useRef, useState } from 'react';
import { Editor } from '@monaco-editor/react';
import useRunCode from '@src/hooks/useRunCode';
import Label from './Label';
import Cursor from '@components/HackathonPage/assets/svgs/icons/Cursor';
import { cn } from '@/lib/utils';
import CodeEditorFooter from './CodeEditorFooter';
import { CODE_SNIPPETS } from './constants';
import LanguageSelector from './LanguageSelector';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import useResizer from '@components/extra/useResizer';

const CodeEditor = () => {
  const globalDispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const editorRef = useRef();
  const [language, setLanguage] = useState('javascript');

  const isMobile = useResizer();

  const setCode = (language, value) => {
    globalDispatch({
      type: 'SET_USER_CODES',
      payload: { ...state.userCodes, [language]: value },
    });
  };

  const onMount = (editor) => {
    editorRef.current = editor;
    editor.focus();
  };

  const onSelect = (language) => {
    setLanguage(language);
    setCode(language, CODE_SNIPPETS[language]);
  };

  const onReset = () => {
    setCode(language, CODE_SNIPPETS[language]);
  };

  const [output, setOutput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const executeCode = useRunCode();

  const runCode = async () => {
    const sourceCode = editorRef.current.getValue();
    if (!sourceCode) return;
    try {
      setIsLoading(true);
      const { run: result } = await executeCode(language, sourceCode);
      console.log('🚀 ~ runCode ~ result:', result);
      setOutput(result.output.split('\n'));
      result.stderr ? setIsError(true) : setIsError(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="cardBorder w-full md:mb-4">
        <div
          className={cn(
            'flex items-center justify-end gap-4 bg-gray-600 px-4 md:hidden md:h-[60px] md:p-2',
            'h-[40px] p-1',
          )}
        >
          <LanguageSelector language={language} onSelect={onSelect} />
        </div>
        <div w="50%">
          <Editor
            options={{
              lineNumbers: 'off',
              fontSize: isMobile ? '12px' : '16px',
              minimap: {
                enabled: false,
              },
            }}
            height="300px"
            theme="vs-dark"
            language={language}
            defaultValue={CODE_SNIPPETS[language]}
            onMount={onMount}
            value={state.userCodes?.[language]}
            onChange={(value) => {
              setCode(language, value);
            }}
          />
        </div>
        <CodeEditorFooter
          onSelect={onSelect}
          language={language}
          runCode={runCode}
          onReset={onReset}
          isLoading={isLoading}
        />
      </div>
      <Label
        icon={<Cursor />}
        content={'Output'}
        contentClassName={'text-white'}
        iconClassName={'h-[20px] md:h-[36px]'}
      ></Label>
      <div
        className={cn(
          'cardBorder min-h-[200px] w-full overflow-auto p-3 text-[12px] text-white md:text-[14px]',
          `${isError && 'errorCardBorder text-[#ff4747]'}`,
        )}
      >
        {output || 'No output present. Run the code to see the output'}
      </div>
    </>
  );
};

export default CodeEditor;
