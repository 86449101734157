import React from 'react';

export default function HeroInfo() {
  const points = [
    <>
      <strong>Test your skills</strong> across essential tech domains
    </>,
    <>
      Receive a personalized <strong>skill assessment report</strong>
    </>,
    <>
      Gain valuable <strong>exposure to industry hiring</strong> standards
    </>,
    <>
      <strong>Experience real-world technical evaluation</strong> rounds from
      leading tech companies
    </>,
  ];

  return (
    <div className="mx-auto flex max-w-4xl flex-col bg-white p-8 text-center md:text-left">
      <p className="mb-2 font-manrope text-[16px] font-extrabold capitalize text-v5-neutral-600 md:text-[20px]">
        Crio-Skill Assessment Test
      </p>
      <h1 className="mb-4 font-manrope text-[30px] font-extrabold leading-tight text-v5-neutral-600 md:text-[50px]">
        Think You're Ready For A Tech Job?
      </h1>
      <p className="mb-6 font-manrope text-[16px] font-medium leading-[31px] text-v5-neutral-500 md:text-[24px]">
        Take a <strong>Free AI-powered mock interview</strong> to assess your
        readiness for top company interviews
      </p>

      <ul className="grid w-full list-inside list-disc gap-4 pl-0 text-left text-v5-neutral-500">
        {points.map((item, idx) => (
          <li key={idx} className="font-rubik text-[10px] md:text-[16px]">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
