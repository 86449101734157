import { cn } from '@/lib/utils';
import React from 'react';

const Button = ({ children, className, onClick = () => {} }) => {
  return (
    <div
      className={cn(
        'fontMPlus flex w-[100px] cursor-pointer items-center justify-center rounded bg-gray-400 font-semibold md:h-[44px] md:w-[176px] md:px-8 md:text-[16px]',
        'h-[30px] px-4 text-[12px]',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Button;
